<template>
  <BaseTooltipModal :link="readMoreUrl">
    <template #title> {{ t('bonuses.referralModalTitle') }}</template>
    <template #subtitle>{{ t('bonuses.referralModalSubtitle') }}</template>
    <TooltipModalBlock :title="t('bonuses.tootlipModalConditions')">
      <TooltipModalBlockItem
        :termin="t('bonuses.referralModalAmount')"
        :description="formattedTrashHold"
      />
    </TooltipModalBlock>
    <TooltipModalBlock :title="t('bonuses.tooltipModalParameters')">
      <TooltipModalBlockItem
        :termin="t('bonuses.referralModalReward')"
        :description="formattedReward"
      />
      <TooltipModalBlockItem
        :termin="t('bonuses.referralModalCurrency')"
        :description="referralCurrency?.code"
      />
    </TooltipModalBlock>
  </BaseTooltipModal>
</template>

<script setup lang="ts">
import BaseTooltipModal from '../TooltipModal/BaseTooltipModal.vue'
import TooltipModalBlock from '../TooltipModal/TooltipModalBlock.vue'
import TooltipModalBlockItem from '../TooltipModal/TooltipModalBlockItem.vue'
import { useReferralBonus } from './useReferralBonus'

const { t } = useI18n()
const { formattedTrashHold, formattedReward, referralCurrency, readMoreUrl } =
  useReferralBonus()
</script>
